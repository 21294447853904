<template>
	<div class="rules">
		<Promo/>
		<Social/>
		<div class="rule-download">
			<a href="https://www.miapuestaweb.com/reglas-miapuestaweb.pdf" target="_blank" rel="noopener noreferrer">
				<img src="../assets/img/pdf-icon.png" alt="Descargar PDF">
				<p>Descargar Reglas</p>
			</a>
		</div>
		<DialogBox>
			<template v-slot:title>
				<h2>Reglas<br> de Apuestas</h2>
			</template>
			<template v-slot:body>
				<ul>
					<li>
						<b>1.</b> El reglamento referente a Apuestas deportivas es una traducción del reglamento de la versión en lengua inglesa, que será controlada a todos los efectos y para todos los derechos y obligaciones de todas las partes por dicha versión del reglamento.
					</li>
					<li>
						<b>2.</b> La dirección se reserva el derecho a cambiar, modificar o añadir reglas a este reglamento, así como modificar procedimientos internos, si lo consideran oportuno sin más aviso que el envío del reglamento actual al sitio Web de <router-link :to="{name: 'Home'}">Miapuestaweb.com</router-link>.
					</li>
					<li>
						<b>3.</b> El cliente se compromete a aceptar estas condiciones al abrir una cuenta o realizar apuestas en <router-link :to="{name: 'Home'}">Miapuestaweb.com</router-link>
					</li>
					<li>
						<b>4.</b> Cualquier tipo de información proporcionada por los agentes de Atención al Cliente es a modo de consejo y está sujeta al Reglamento de <router-link :to="{name: 'Home'}">Miapuestaweb.com</router-link> arriba mencionado. Aunque haremos todo lo posible por asegurar que la información facilitada al cliente sea correcta, es en última instancia responsabilidad del cliente asegurarse que entiendan a qué están apostando, así como los términos y condiciones.
					</li>
				</ul>
				<h3>Realizacón de Apuestas</h3>
				<ul>
					<li>
						<b>1.</b> Una apuesta se considerará nula si no se ha recibido por completo.
					</li>
					<li>
						<b>2.</b> Una vez que un cliente confirma una apuesta recibirá un acuse de recibo (que incluye el número identificador de la apuesta), que NO es una confirmación de la aceptación definitiva por parte de Miapuestaweb.com.
					</li>
					<li>
						<b>3.</b> En caso de que un cliente envíe múltiples copias de una misma apuesta, ésta será tratada como una sola. La compañía se reserva el derecho de anular apuestas en caso que haya sospecha de coincidencia de dos o más usuarios.
					</li>
					<li>
						<b>4.</b> En caso de recibir varias apuestas combinadas, que incluyan idénticas selecciones o muy similares, de diferentes clientes y en un corto espacio de tiempo, Miapuestaweb.com se reserva el derecho de anular todas estas apuestas, incluso después de que las apuestas hayan sido resueltas.
					</li>
					<li>
						<b>5.</b> Miapuestaweb.com se reserva el derecho de rechazar cualquier apuesta o parte de una apuesta en cualquier momento sin previa explicación. Además, Miapuestaweb.com se reserva el derecho de cerrar la cuenta de un usuario y recuperar el saldo de esta cuenta sin dar explicación. En este caso se cumplir con las apuestas pendientes.
					</li>
					<li>
						<b>6.</b> Miapuestaweb.com se reserva el derecho, en cualquier momento (incluso después de que una apuesta haya sido resuelta), de (i) rechazar cualquier apuesta o parte de una apuesta; o (ii) anular cualquier apuesta o parte de una apuesta siempre que Miapuestaweb.com concluya, a su única discreción, que existe una complicidad entre cuentas y/o clientes. En este caso, las apuestas restantes que no sean susceptibles de ser rechazadas o anuladas serán válidas. Asimismo, Miapuestaweb.com se reserva el derecho a adoptar las medidas legales necesarias contra aquellos clientes respecto a los cuales una autoridad reguladora considere que ha existido colusión.
					</li>
					<li>
						<b>7.</b> En el caso de que la integridad de un evento deportivo y/o partido o enfrentamiento está en duda, Miapuestaweb.com se reserva el derecho a anular todas las apuestas relacionadas con este evento (incluidas las combinadas). Las cantidades apostadas en dichos eventos serán reembolsadas en las cuentas de los clientes.
					</li>
					<li>
						<b>8.</b> Es condición para que Miapuestaweb.com acepte las apuestas de un Cliente, y para poder realizar una apuesta con Miapuestaweb.com, que el cliente confirme que:
						Usted, el cliente, no tiene prohibida la participación en la apuesta por ningún término de su contrato de trabajo o por alguna norma del Organismo de Gobierno Deportivo del que dependa;
						Usted, el cliente, no está al tanto de ninguna circunstancia que pudiera significar que la realización de la apuesta infrinja una norma de apuesta impuesta por un Organismo de Gobierno Deportivo; y que
						Cuando la apuesta es realizada al resultado de una carrera, competición u otro evento o proceso, o a la posibilidad de algo ocurra o no ocurra, usted, el cliente, no sabe el resultado del evento.
						En el caso de que dicha confirmación hecha por usted, el cliente, sea probada como falsa, perder su cantidad apostada y Miapuestaweb.com no tendrá la obligación de pagar ninguna ganancia, que de otro modo podría haber sido pagada, respecto a la apuesta.
					</li>
					<li>
						<b>9.</b> La apuesta mínima que se puede realizar en línea es de 5 Bs.F.
					</li>
					<li>
						<b>10.</b> Cuando un cliente da instrucciones poco claras Miapuestaweb.com se reserva el derecho a dividir el total apostado entre los resultados más importantes. En el caso que esto no se pudiera hacer, Miapuestaweb.com se reserva el derecho a anular toda la apuesta. En cualquiera de ambas situaciones, la decisión de la compañía es definitiva.
					</li>
					<li>
						<b>11.</b> Los clientes no podrán cancelar o cambiar una apuesta una vez haya sido realizada y aceptada.
					</li>
					<li>
						<b>12.</b> Las apuestas múltiples son aceptadas, sin embargo no se aceptarán apuestas de este tipo si el resultado de una parte repercute en el resultado de la otra.
					</li>
					<li>
						<b>13.</b> Todas las cotizaciones están sujetas a variación, quedando fijas una vez se haya confirmado y aceptado.
					</li>
					<li>
						<b>14.</b> Miapuestaweb.com se reserva el derecho de rechazar o anular cualquier apuesta.
					</li>
					<li>
						<b>15.</b> Miapuestaweb.com se reserva el derecho de retirar de la cuenta de un cliente cualquier importe que considere, a su única discreción, que ha sido obtenido como resultado de algún error, mediante alguna actividad fraudulenta o cualquier otra actividad de cualquier naturaleza que Miapuestaweb.com considere ha sido realizada de mala fe.
					</li>
					<li>
						<b>16.</b> Las apuestas serán aceptadas hasta la hora anunciada. Si una apuesta queda aceptada inadvertidamente cuando un evento ya ha dado comienzo, Miapuestaweb.com se reserva el derecho de anular todas las referidas a dicho evento.
					</li>
					<li>
						<b>17.</b> Todas las cotizaciones hechas en mensajes de texto promocionales están correctas en el momento de ser enviados. Las cotizaciones pueden subir o bajar entre el momento de enviar el mensaje y el comienzo del evento. La única cotización que Miapuestaweb.com aceptaría será la cotización ofrecida al momento de colocar la apuesta.
					</li>					
				</ul>

				<h3>Reglas sobre ingresos</h3>

				<ul>
					<li>
						<b>1.</b> Ningún trabajador de Miapuestaweb.com podrá ofrecer crédito alguno a un cliente. De hecho, es responsabilidad del mismo saber cuánto dinero le queda en cuenta para poder apostar correctamente. Miapuestaweb.com se reserva el derecho a anular cualquier apuesta que haya podido ser aceptada inadvertidamente estando la cuenta del usuario con fondos insuficientes para cubrir la totalidad de una apuesta.
					</li>
					<li>
						<b>2.</b> En el supuesto de que se valide crédito en la cuenta de un usuario por error, es responsabilidad del usuario el informar a Miapuestaweb.com de dicho error. Cualquier beneficio obtenido después del error sin que el usuario haya informado a la empresa será anulado y devuelto a la empresa, tenga relación o no con el saldo proveniente del error.
					</li>
					<li>
						<b>3.</b> Las ganancias serán ingresadas en las cuentas de usuario una vez se haya confirmado el resultado final del evento.
					</li>
					<li>
						<b>4.</b> Miapuestaweb.com en ningún caso, y bajo ninguna circunstancia, se hace responsable de los datos o pérdidas sin limitación que se consideren o declaren procedentes a causa de este sitio Web o su contenido, incluyendo sin limitación, retrasos o interrupciones en la operación o transmisión, errores en las líneas de comunicación, uso incorrecto del sitio o su contenido por parte de los usuarios, o cualquier error u omisión en el contenido
					</li>
					<li>
						<b>5.</b> Miapuestaweb.com se reserva el derecho de anular alguna o todas las apuestas realizadas por un grupo de gente en un intento de hacer fraude a la compañía. Esto incluye a personas, parientes, organizaciones, corredores de apuestas y sus empleados.
					</li>
					<li>
						<b>6.</b> La dirección se reserva el derecho de cancelar una solicitud de cobro de ganancias si el usuario no ha invertido (jugado) la totalidad de los depósitos realizados, con tal de prevenir acciones fraudulentas.
					</li>
					<li>
						<b>7.</b> Miapuestaweb.com realiza inspecciones sobre los movimientos de cuentas bancarias, en caso de presentarse situaciones fraudulentas.
					</li>					
				</ul>

				<h3>En Caso de Disputa</h3>
				<ul>
					<li>
						<b>1.</b> Miapuestaweb.com no se responsabiliza de ningún error del texto o humano en la oferta de cotizaciones y apuestas, a excepción de las deliberadas. Miapuestaweb.com se reserva el derecho de corregir el error o bien anular las apuestas afectadas, en cuyo caso se devolver la cantidad de las apuestas simples y se anularán los eventos implicados en las combinadas y múltiples.
					</li>
				</ul>

				<h3>Máxima Ganancia Diaria</h3>
				<ul>
					<li>
						<b>1.</b> Las apuestas son a ganador.
					</li>
					<li>
						<b>2.</b> El cierre de las apuestas se basa en la cotización y posición en el momento en que se acepta la apuesta. Miapuestaweb.com se reserva el derecho de cambiar los términos de posición de las apuestas a posición antes o durante un evento o campeonato.
					</li>
					<li>
						<b>3.</b> En caso de empate, la cantidad apostada se divide por el número de corredores que han empatado. La cantidad apostada se multiplica por la cotización del corredor dividida por el número de corredores empatados.
					</li>
					<li>
						<b>4.</b> Cuando una apuesta simple se anula el saldo invertido en la misma se devuelve a la cuenta de usuario. En aquellos eventos que no se disputan por cualquier razón, el saldo puede ser, o no, devuelto, según el reglamento específico de cada deporte. En apuestas compuestas o parlay, las apuestas continuarán sin contar con la selección anulada (una doble pasa a apuesta sencilla, una triple pasa a apuesta doble, etc.)
					</li>
					<li>
						<b>5.</b> El resultado oficial es determinante para el cierre de las apuestas excepto cuando existen reglas específicas que indican lo contrario. Posteriores cambios en los resultados, por acciones disciplinarias u otros motivos no se tendrán en cuenta. Cuando, a juicio de Miapuestaweb.com, no hay resultados oficiales disponibles, el resultado será: determinado por una autoridad independiente, el veredicto de la cual será final para todos los propósitos, o por otro lado, determinado en la opinión razonable de Miapuestaweb.com. Esta opinión será formada en función de todas las pruebas razonables disponibles.
					</li>
					<li>
						<b>6.</b> El cierre definitivo de una apuesta se producirá cuando Miapuestaweb.com considere que el resultado final y el consiguiente cierre se ha producido satisfactoriamente en función de todas las pruebas razonables disponibles.
					</li>
				</ul>

				<h3>
					Apuestas Relacionadas
				</h3>
				<ul>
					<li>
						<b>1.</b> No se aceptan apuestas combinadas sobre un mismo evento en el que el resultado de una elección afecta o queda afectado por el resultado de otra elección. Eventualmente se pueden ofrecer apuestas especiales que combinen estas opciones. Si no se oferta dicha apuesta especial, y se acepta una apuesta de este tipo por error, la apuesta será tratada como dos apuestas simples y la cantidad apostada será dividida entre las dos apuestas.
					</li>
					<li>
						<b>2.</b> Cuando las partes relacionadas de una apuesta se resuelven en diferentes momentos, incluso dentro del mismo torneo, la apuesta quedará establecida tal y como estaba introducida. Se utilizará la cotización del evento una vez finalizada la primera parte del evento.
					</li>

				</ul>

				<h3>Cambio de línea</h3>
					<ul><li>
						<b>1.</b> Todas las cotizaciones están sujetas a variaciones en cualquier momento y sin previo aviso.
						</li>
					</ul>	

				<h3>Tiempo de Aceptación</h3>
					<ul><li>
						<b>1.</b> Todas las apuestas seleccionadas con los precios fijados por Miapuestaweb.com serán aceptadas hasta el momento antes de la hora de inicio fijada en la página. En el supuesto de que se haya aceptado dicha apuesta por error, Miapuestaweb.com se reserva el derecho a anular las apuestas implicadas o parte de ellas. Si se ofrecen cotizaciones en directo para el partido, la apuesta será aceptada a la cotización indicada.
						</li>
					</ul>	

				<h3>Apuestas en directo</h3>
					<ul><li>
						<b>1.</b> Miapuestaweb.com intenta realizar una cotización justa de los eventos que transcurren durante un partido en directo basándose en la buena fe del comentarista de TV. Pueden presentarse situaciones en las que no es posible determinar con precisión lo sucedido. En caso de que se requiera un examen más preciso, la retransmisión del locutor de televisión se interrumpe o no puede comenzar a tiempo o se produce un fallo técnico en los medios, se reserva el derecho a comprobar posteriormente el cierre de la apuesta o a anularla. Si no hay ningún movimiento posterior en el evento una vez aceptada la apuesta en directo, estas apuestas serán nulas a no ser que la reglamentación específica para ese deporte indique lo contrario. Si resulta que el locutor de TV falla en uno o más eventos o se cierra una apuesta incorrectamente, Miapuestaweb.com se reserva el derecho a realizar ajustes retrospectivos positivos o negativos en las cuentas. Confiamos en las actuaciones de los oficiales que actúan durante el curso del juego, partido o torneo. Estas decisiones serán finales sin tener en cuenta si varían posteriormente por ser reclamadas por la otra parte.				
						</li>
					</ul>	
			</template>
		</DialogBox>
	</div>
</template>

<script>
import Promo from '../components/Promo.vue'
import Social from '../components/Social.vue'
import DialogBox from '../components/DialogBox.vue'
	export default {
		name: 'Rule',
		title: 'Reglas',
		components: {
			Promo,
			DialogBox,
			Social,
		}
	}
</script>

<style lang="scss" scoped>

	.rules {
		width: 100%;
		height: 100%;
		margin-top: -48%;

		.rule-download {
			position: absolute;
			top: -8.8%;
			left: 6%;			
			z-index: 4;

			a {
				@include flex-container(center, center, center);
				font-size: 35px;

				p {
					width: 100px;
					margin-top: 13px;
					margin-left: 33px;
				}
			}
		}
		
		.bars {
			position: absolute;
			z-index: 1;
		}	

		#rigth-bars {
			right: -3.5%;
			top: -52%;
		}

		#left-bar {
			left: 43.4%;
			top: -14.5%;
		}

		#orange-bars {
			right: 3.1%;
			top: -13.5%;
			animation-duration: 2s;
		}		
	}

	@include mobile {
		
		.rules {

			.rule-download {
				top: -15%;
				left: -5.5%;

				a {
					font-size: 14px;
					color: $black;
					text-align: left;

					p {
						margin-top: 13px;
						margin-left: 15px;
					}
				}

				img {
					width: 18%;
				}
			}
		}
	}

</style>